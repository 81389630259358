<template>
  <form :class="memoFormClassObject">
    <div :class="sectionClassObject">
      <div :class="{ 'c-section__inner': editable }">
        <p class="c-title3 c-title3--center">
          <template v-if="editable">今の感想をメモしておきましょう</template>
          <template v-else>あなたの感想メモ</template>
        </p>

        <div
          class="p-memoForm__item"
          v-for="(impression, name) in formItems.impressions"
          :key="impression.index">
          <p class="c-title6">
            {{ impression.title }}
          </p>

          <ul class="c-buttonGroup" :class="{ 'u-pe-none': !editable }">
            <li
              v-for="(rate, index) in impressionRates"
              :key="index"
              class="c-buttonGroup__item"
              v-ripple>
              <input
                v-model="impression.value"
                type="radio"
                :name="name"
                :id="name + (index + 1)"
                class="c-buttonGroup__radio p-memoForm__radio"
                :class="`p-memoForm__radio--${index + 1}`"
                :value="impressionRates[index].value" />
              <label
                class="c-buttonGroup__label p-memoForm__label"
                :class="`p-memoForm__label--${index + 1}`">
                <span class="c-buttonGroup__label__text">
                  {{ rate.label }}
                </span>
              </label>
            </li>
          </ul>
        </div>
        <!-- /.p-memoForm__item -->

        <div class="p-memoForm__item p-memoForm__item--textarea">
          <BaseTextarea
            v-model="formItems.comment"
            placeholder="残しておきたい味の感想をメモできます"
            :readonly="!editable" />
        </div>
        <!-- /.p-memoForm__item -->

        <div
          class="p-memoForm__item"
          :class="{ 'p-memoForm__item--saveBtn': editable }">
          <Button
            v-if="editable"
            style-type="quaternary"
            size="sm"
            fluid
            @click="createMemo">
            メモを保存する
          </Button>
          <Button
            v-else
            style-type="tertiary"
            size="sm"
            fluid
            action
            @click="editMemo">
            <Icon
              name="pen"
              class="c-textButton__icon c-textButton__icon--prepend" />
            メモを編集する
          </Button>
        </div>
        <!-- /.p-memoForm__item -->
      </div>
      <!-- /.c-section__inner -->
    </div>
    <!-- /.c-section -->
  </form>
  <!-- /.p-memoForm -->
</template>

<script>
export default {
  props: {
    memo: {
      type: Object,
      required: true
    },

    editable: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    formItems: {
      impressions: {
        aroma: {
          title: '香り',
          value: undefined
        },

        sweetness: {
          title: '甘味',
          value: undefined
        },

        sourness: {
          title: '酸味',
          value: undefined
        },

        bitterness: {
          title: '苦味',
          value: undefined
        },

        richness: {
          title: '濃厚感',
          value: undefined
        }
      },

      comment: ''
    },

    sectionBlockName: 'c-section',
    memoFormBlockName: 'p-memoForm'
  }),

  computed: {
    /**
     * 感想（物足りない、ちょうど良い、強い）を数値化したもの
     */
    impressionRates() {
      return [
        { label: '物足りない', value: 100 },
        { label: 'ちょうど良い', value: 200 },
        { label: '強い', value: 300 }
      ];
    },

    sectionBgClass() {
      return this.editable ? 'c-section--grey04Bg' : '';
    },

    sectionClassObject() {
      return [this.sectionBlockName, this.sectionBgClass];
    },

    memoFormClass() {
      return this.editable ? '' : 'p-memoForm--readonly';
    },

    memoFormClassObject() {
      return ['c-form', this.memoFormBlockName, this.memoFormClass];
    },

    submitButtonType() {
      return this.editable ? 'quaternary' : 'tertiary';
    }
  },

  created() {
    this.loadMemo();
  },

  methods: {
    loadMemo() {
      for (const key of Object.keys(this.formItems.impressions)) {
        this.formItems.impressions[key].value = this._loadImpressionRate(
          this.memo?.[key]
        );
      }
      this.formItems.comment = this.memo.comment || '';
    },

    /**
     * @param {number | null} rate 感想（物足りない、ちょうど良い、強い）を数値化したもの
     * @returns {number}
     */
    _loadImpressionRate(rate) {
      return this.impressionRates.map((v) => v.value).includes(rate)
        ? rate
        : this.impressionRates[1].value;
    },

    createMemo() {
      const payload = {
        ...this.memo,
        aroma: this.formItems.impressions.aroma.value,
        sweetness: this.formItems.impressions.sweetness.value,
        sourness: this.formItems.impressions.sourness.value,
        bitterness: this.formItems.impressions.bitterness.value,
        richness: this.formItems.impressions.richness.value,
        comment: this.formItems.comment
      };
      this.$emit('update', payload);
      this.$emit('toggle');
    },

    editMemo() {
      this.$emit('toggle');
    }
  }
};
</script>
